<template>
  <tr>
    <td class="text-center">{{ idx + 1 }}</td>
    <td>
      <div :class="{'has-error': $v.data.dueDate.$error}">
        <dx-input-date
          id="due-date"
          class="form-input"
          v-model="data.dueDate"
          @blur="$v.data.dueDate.$touch()"
        />
      </div>
    </td>
    <td>
      <div :class="{'has-error': $v.data.value.$error}">
        <dx-input-number id="current-value"
                         class="form-input text-right"
                         readonly="true"
                         v-model="data.value"
                         :precision="2"
                         @blur="$v.data.value.$touch()"
        />
      </div>
    </td>
    <td v-if="!isRepeatPayment">
      <dx-input-number id="current-value"
                       class="form-input text-right"
                       v-model="data.percent"
                       readonly="true"
                       :precision="2"
      />
    </td>
    <td>
      <div :class="{'has-error': $v.data.methodId.$error}">
        <select id="method"
                class="form-select"
                v-model="data.methodId"
                @blur="$v.data.methodId.$touch()">
          <option value="">[Selecione uma forma]</option>
          <option v-for="item in methods" :value="item.id"
                  :key="item.id">{{ item.name }}</option>
        </select>
      </div>
    </td>
    <td>
      <div :class="{'has-error': $v.data.bankAccountId.$error}">
        <select id="bank-account"
                class="form-select"
                v-model="data.bankAccountId"
                @blur="$v.data.bankAccountId.$touch()">
          <option value="">[Selecione uma conta]</option>
          <option v-for="item in bankAccounts" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </td>
  </tr>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  date,
  minDate,
} from 'src/data/validators';
import moment from 'moment';

export default {
  props: {
    idx: {
      type: Number,
    },
    bankAccounts: {
      type: Array,
    },
    methods: {
      type: Array,
    },
    isRepeatPayment: {
      type: Boolean,
    },
    data: {
      issueDate: {
        type: Date,
      },
      dueDate: {
        type: Date,
      },
      value: {
        type: Number,
      },
      percent: {
        type: Number,
      },
      methodId: {
        type: Number,
      },
      bankAccountId: {
        type: Number,
      },
    },
  },
  validations() {
    const issueDate = this.data.issueDate || moment().format('YYYY-MM-DD');
    return {
      data: {
        dueDate: { required, date, minDate: minDate(issueDate) },
        value: { required },
        methodId: { required },
        bankAccountId: { required },
      },
    };
  },
  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$error;
    },
  },
};
</script>

<style lang="scss">

</style>
