<template>
  <div class="page-container financial-transactions-page">
    <portal to="page-name">Movimentações</portal>
    <div class="card card-page">
      <div class="card-header">
        <button class="btn btn-primary btn-icon-left float-right" @click="open">
          <fa-icon :icon="['fal', 'plus']"></fa-icon>
          Novo lançamento
        </button>
        <button class="btn btn-neutral btn-icon-left float-right mr-2"
                :class="{loading: printing}" @click="print"
                :disabled="data.items.length === 0 || printing">
          <fa-icon :icon="['fal', 'print']"></fa-icon>
          Imprimir Relatório
        </button>
        <h1 class="card-title">Movimentação financeira</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-2 form-group">
            <label class="form-label">Data</label>
            <select id="filter-date"
                    class="form-select" v-model="filter.dateType">
              <option value="issueDate">Emissão</option>
              <option value="dueDate">Vencimento</option>
              <option value="paymentDate">Baixa</option>
            </select>
          </div>
          <div class="column col-2 col-md-12 form-group"
               :class="{'has-error': $v.filter.startDate.$error}">
            <label class="form-label">Início</label>
            <dx-input-date type="text" id="filter-start-date"
                           name="filter-start-date"
                           @blur="$v.filter.startDate.$touch()"
                           v-model="filter.startDate" class="form-input"
                           placeholder="00/00/0000" autocomplete="nope"/>
          </div>
          <div class="column col-2 col-md-12 form-group"
               :class="{'has-error': $v.filter.endDate.$error}">
            <label class="form-label">Término</label>
            <dx-input-date type="text" id="filter-end-date"
                           name="filter-end-date"
                           @blur="$v.filter.endDate.$touch()"
                           v-model="filter.endDate" class="form-input"
                           placeholder="00/00/0000" autocomplete="nope"/>
          </div>
          <div class="column col-2 col-md-12 form-group">
            <label class="form-label">Tipo</label>
            <select id="filter-type" name="filter-type"
                    class="form-select" v-model="filter.type">
              <option value="">Todos</option>
              <option value="expense">Documentos a pagar</option>
              <option value="revenue">Documentos a receber</option>
            </select>
          </div>
          <div class="column col-2 col-md-12 form-group">
            <label class="form-label">Status</label>
            <select id="filter-status" name="filter-status"
                    class="form-select" v-model="filter.status">
              <option value="">Todos</option>
              <option value="canceled">Cancelados</option>
              <option value="paid">Baixados</option>
              <option value="open">Pendentes</option>
            </select>
          </div>
          <div class="column col-2 col-md-12 form-group">
            <label class="form-label">Repasses</label>
            <select id="filter-transfer" name="filter-transfer"
                    class="form-select" v-model="filter.transfer">
              <option value="">Todos</option>
              <option value="true">Apenas repasses</option>
            </select>
          </div>
        </div>
        <div class="columns">
          <div class="column col-3 col-md-12 form-group">
            <label class="form-label">Forma de pagamento</label>
            <select id="filter-payment" name="filter-payment"
                    class="form-select" v-model="filter.methodId">
              <option value="">Todos</option>
              <option v-for="(item, i) in paymentMethods" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-3 col-md-12 form-group">
            <label class="form-label">
              Conta bancária / caixa
            </label>
            <select id="filter-bank-account"
                    class="form-select" v-model="filter.bankAccountId">
              <option value="">Todos</option>
              <option v-for="(item, i) in bankAccounts" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-2 form-group">
            <label for="filter-search" class="form-label">
              Documento
            </label>
            <input type="text" id="filter-document" name="filter-search"
                   class="form-input" v-model="filter.document" maxlength="20"
                   autocomplete="nope" placeholder="XT7A9SAQ">
          </div>
          <div class="column form-group">
            <label for="filter-search" class="form-label">
              Pagador / Favorecido
            </label>
            <input type="text" id="filter-search" name="filter-search"
                   class="form-input" v-model="filter.search" maxlength="100"
                   autocomplete="nope" placeholder="José da Silva...">
          </div>
          <div class="column col-auto form-group mb-2"
               style="align-self: flex-end">
            <button class="btn btn-gray btn-icon-left" @click="load">
              <fa-icon :icon="['fal', 'search']"/>
              Pesquisar
            </button>
          </div>
        </div>
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Movimentação financeira</p>
              <p class="empty-subtitle">
                Nenhuma movimentação encontrado. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th class="text-center" width="40px">Tipo</th>
                <th class="text-center">Status</th>
                <th>Documento</th>
                <th class="text-center">Emissão</th>
                <th class="text-center">Vencimento</th>
                <th class="text-center">Baixa</th>
                <th>Pagador / Favorecido</th>
                <th>Cobrança</th>
                <th class="text-right">Valor</th>
                <th style="width: 4.6rem;"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in data.items" :key="i">
                <td class="text-center">
                  <div class="tooltip tooltip-right" :data-tooltip="'Pagar'"
                       v-if="item.type === 'expense'">
                    <fa-icon class="text-warning" :icon="['fal', 'arrow-up']"/>
                  </div>
                  <div class="tooltip tooltip-right" :data-tooltip="'Receber'" v-else>
                    <fa-icon class="text-secondary" :icon="['fal', 'arrow-down']"/>
                  </div>
                </td>
                <td class="text-center">
                  <div class="tooltip tooltip-right" v-if="item.status === 'open'"
                       :data-tooltip="'Pendente'">
                    <fa-icon class="text-gray" :icon="['fal', 'minus']"/>
                  </div>
                  <div class="tooltip tooltip-right" v-if="item.status === 'paid'"
                       :data-tooltip="'Realizado'">
                    <fa-icon class="text-info" :icon="['fal', 'check']"/>
                  </div>
                  <div class="tooltip tooltip-right" v-if="item.status === 'canceled'"
                       :data-tooltip="'Cancelado'">
                    <fa-icon class="text-error" :icon="['fal', 'times']"/>
                  </div>
                </td>
                <td>
                  <span class="tooltip hover-link" data-tooltip="Detalhar documento"
                        @click="edit(item)">
                    {{ item.document }}
                  </span>
                </td>
                <td class="text-center">
                  {{ item.issueDate | date }}
                </td>
                <td class="text-center">
                  {{ item.dueDate | date }}
                </td>
                <td class="text-center">
                  <span v-if="item.payments && item.payments.length > 0">
                    {{ item.payments[0].date | date }}
                  </span>
                  <span v-else>-</span>
                </td>
                <td>{{ item.entity.name }}</td>
                <td>
                  <span>{{ item.method.name }}</span>
                  <span v-if="item.installment.total > 1">
                    ({{ item.installment.current }}/{{ item.installment.total }})
                  </span>
                </td>
                <td class="text-right" style="min-width: 100px">
                  <div class="text-primary" v-if="item.type === 'revenue'">
                    <span v-if="item.payments && item.payments.length > 0">
                      {{ item.payments[0].total | currency }}
                    </span>
                    <span v-else>{{ item.value.final | currency }}</span>
                  </div>
                  <div class="text-error" v-else>
                    <span v-if="item.payments && item.payments.length > 0">
                      {{ item.payments[0].total * -1 | currency }}
                    </span>
                    <span v-else>{{ (item.value.final * -1) | currency }}</span>
                  </div>
                </td>
                <td class="text-right">
                  <button
                    v-if="item.type === 'revenue'"
                    class="btn btn-sm btn-gray btn-icon btn-action mr-1"
                    :class="[invoiceModal.loading ? 'loading' : 'tooltip']"
                    data-tooltip="Nota fiscal"
                    @click="openInvoiceModal(item)"
                  ><fa-icon :icon="['fal', 'file-invoice-dollar']"/></button>

                  <template v-if="item.status === 'open'">
                    <button class="btn tooltip btn-sm btn-action btn-icon btn-secondary mr-1"
                            data-tooltip="Alterar" @click="edit(item)">
                      <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                    </button>
                    <button class="btn tooltip btn-sm btn-action btn-icon btn-warning"
                            @click="payment(item.id)" data-tooltip="Baixar">
                      <fa-icon :icon="['fal', 'check-circle']"></fa-icon>
                    </button>
                  </template>
                  <template v-else>
                    <button class="btn tooltip btn-sm btn-action btn-icon btn-gray mr-1"
                            data-tooltip="Imprimir" disabled>
                      <fa-icon :icon="['fal', 'print']"></fa-icon>
                    </button>
                    <button class="btn tooltip btn-sm btn-action btn-icon btn-warning"
                            @click="reverse(item)" data-tooltip="Estornar">
                      <fa-icon :icon="['fal', 'undo']"></fa-icon>
                    </button>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </template>
      </div>
      <div class="card-footer text-right" v-if="data.items.length > 0">
        Total:<span class="ml-2 text-bold">
        {{ total | currency }}
      </span>
      </div>
    </div>

    <detail-modal
      id="modal-detail"
      v-if="detail.show"
      :show="detail.show"
      :transactionId="detail.id"
      @close="closeDetail"
    />

    <invoice-detail-or-create
      v-if="invoiceModal.show"
      :show="invoiceModal.show"
      :value="invoiceModal.value"
      :borrower="invoiceModal.borrower"
      :ref-id="invoiceModal.refId"
      @loading="invoiceModal.loading = $event"
      @close="closeInvoiceModal"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { date, minDate } from '@/data/validators';
import localforage from 'localforage';
import InvoiceDetailOrCreate from '@/components/invoice/InvoiceDetailOrCreate.vue';
import {
  PRINT_FINANCIAL_TRANSACTION_LIST,
  REVERSE_FINANCIAL_TRANSACTION,
  PAY_FINANCIAL_TRANSACTION,
} from '@/data/actions/modules/financial';
import DetailModal from './Detail.vue';
import { parse as parseBoolean } from '../../../../helpers/boolean';

export default {
  components: {
    InvoiceDetailOrCreate,
    DetailModal,
  },
  data() {
    return {
      path: '/financial-transactions',
      loading: false,
      printing: false,
      detail: {
        id: '',
        show: false,
      },
      paymentMethods: [],
      bankAccounts: [],
      filter: {
        type: '',
        status: 'open',
        dateType: 'dueDate',
        startDate: moment()
          .format('YYYY-MM-DD'),
        endDate: moment()
          .add(15, 'days')
          .format('YYYY-MM-DD'),
        methodId: '',
        bankAccountId: '',
        transfer: '',
        document: '',
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
      invoiceModal: {
        loading: false,
        show: false,
        id: null,
        refId: null,
        value: 0,
        borrower: null,
      },
    };
  },
  mounted() {
    this.loadPaymentMethods();
    this.loadBankAccounts();
    this.loadFilters()
      .then(() => {
        this.load();
      });
  },
  computed: {
    total() {
      return this.data.items
        .reduce((a, b) => {
          let value;
          if (b.payments && b.payments.length > 0) {
            value = b.payments[0].total;
          } else {
            value = b.value.final;
          }
          a += b.type === 'revenue' ? value : value * -1;
          return a;
        }, 0);
    },
    canAccessPrintTransaction() {
      if (PRINT_FINANCIAL_TRANSACTION_LIST) {
        return this.$can(PRINT_FINANCIAL_TRANSACTION_LIST);
      }
      return true;
    },
    canAccessReverseTransaction() {
      if (REVERSE_FINANCIAL_TRANSACTION) {
        return this.$can(REVERSE_FINANCIAL_TRANSACTION);
      }
      return true;
    },
    canAccessPayTransaction() {
      if (PAY_FINANCIAL_TRANSACTION) {
        return this.$can(PAY_FINANCIAL_TRANSACTION);
      }
      return true;
    },
  },
  validations() {
    const rules = {
      filter: {
        startDate: {
          date,
          required,
        },
        endDate: {
          date,
          required,
        },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  methods: {
    loadFilters() {
      return localforage.getItem('financialFilters')
        .then((filters) => {
          if (filters) {
            if (filters.dateType) {
              this.filter.dateType = filters.dateType;
            }

            if (filters.startDate) {
              this.filter.startDate = filters.startDate;
            }

            if (filters.endDate) {
              this.filter.endDate = filters.endDate;
            }

            if (filters.type) {
              this.filter.type = filters.type;
            }

            if (parseBoolean(filters.transfer)) {
              this.filter.transfer = filters.transfer;
            }

            if (filters.status) {
              this.filter.status = filters.status;
            }

            if (filters.bankAccountId) {
              this.filter.bankAccountId = filters.bankAccountId;
            }

            if (filters.methodId) {
              this.filter.methodId = filters.methodId;
            }

            if (filters.document) {
              this.filter.document = filters.document;
            }

            if (filters.search) {
              this.filter.search = filters.search;
            }

            const duration = moment.duration(moment(this.filter.endDate)
              .diff(moment(this.filter.startDate))).asMonths();

            if (duration >= 7) {
              this.filter.startDate = moment().format('YYYY-MM-DD');
              this.filter.endDate = moment().add(1, 'months')
                .format('YYYY-MM-DD');
            }
          }
        })
        .catch(() => {});
    },
    loadPaymentMethods() {
      const params = {
        active: true,
        limit: 0,
      };
      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.paymentMethods = data.items;
        })
        .catch(() => {
        });
    },
    loadBankAccounts() {
      const params = {
        active: true,
        limit: 0,
      };
      return this.$http.get('/bank-accounts', { params })
        .then(({ data }) => {
          this.bankAccounts = data.items;
        })
        .catch(() => {
        });
    },
    load() {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return;
      }

      const duration = moment.duration(moment(this.filter.endDate)
        .diff(moment(this.filter.startDate))).asMonths();

      if (duration >= 7) {
        this.$toast.show('Período máximo (6 meses)', { type: 'error' });
        return;
      }

      localforage.setItem('financialFilters', this.filter);

      this.loading = true;

      const params = {};

      if (this.filter.dateType) {
        params.dateType = this.filter.dateType;
      }

      if (this.filter.startDate) {
        params.startDate = this.filter.startDate;
      }

      if (this.filter.endDate) {
        params.endDate = this.filter.endDate;
      }

      if (this.filter.type) {
        params.type = this.filter.type;
      }

      if (parseBoolean(this.filter.transfer)) {
        params.transfer = this.filter.transfer;
      }

      if (this.filter.status) {
        params.status = this.filter.status;
      }

      if (this.filter.bankAccountId) {
        params.bankAccountId = this.filter.bankAccountId;
      }

      if (this.filter.methodId) {
        params.methodId = this.filter.methodId;
      }

      if (this.filter.document) {
        params.document = this.filter.document;
      }

      if (this.filter.search) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    open() {
      this.$router.push('/financial/transactions/create');
    },
    edit(item) {
      if (!item.id) {
        return;
      }
      if (item.status === 'paid' || (item.services && item.services.length > 0)) {
        this.detail.id = item.id;
        this.detail.show = true;
      } else {
        this.$router.push(`/financial/transactions/${item.id}/edit`);
      }
    },
    print() {
      if (!this.canAccessPrintTransaction) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return false;
      }
      this.printing = true;
      const ids = this.data.items.map(({ id }) => id);
      const params = {
        ids: ids.join(','),
        status: this.filter.status,
        dateType: this.filter.dateType,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
      };
      return this.$file
        .print(`${this.path}/print`, params)
        .catch(() => {})
        .finally(() => {
          this.printing = false;
        });
    },
    closeDetail(save) {
      this.detail.show = false;
      if (save) {
        this.load();
      }
    },
    payment(id) {
      if (!this.canAccessPayTransaction) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$router.push(`/financial/transactions/${id}/payment`);
    },
    reverse(item) {
      if (!this.canAccessReverseTransaction) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente estornar este documento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: close => this.$http
              .put(`${this.path}/${item.id}/reverse`)
              .catch((e) => {
                if (e.response
                  && e.response.data
                  && e.response.data.message) {
                  this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
                }
              })
              .finally(() => {
                close();
                this.load();
              }),
          },
        ],
      });
    },
    async openInvoiceModal(item) {
      this.invoiceModal.loading = true;
      try {
        const { data } = await this.$http.get(`/entities/${item.entity.id}`);
        this.invoiceModal.value = item.value.total;
        this.invoiceModal.borrower = data;
        this.invoiceModal.refId = item.id;
        this.invoiceModal.loading = false;
        this.invoiceModal.show = true;
      } catch (e) {
        this.$toast.error(e);
        this.invoiceModal.loading = false;
      }
    },
    closeInvoiceModal() {
      this.invoiceModal.value = null;
      this.invoiceModal.borrower = null;
      this.invoiceModal.refId = null;
      this.invoiceModal.show = false;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.financial-transactions-page {
  .table th, .table td {
    font-size: 0.56rem;
  }
}

#transaction-tiles {
  display: flex;
  padding: 0 0 $layout-spacing 0;
  color: #fff;

  .tile-item {
    background-color: $secondary-color;
    border-radius: $border-radius;
    flex: 1;
    margin-left: $layout-spacing;
    padding: 10px;
    position: relative;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }
  }

  .tile-label {
    font-size: $font-size-sm;
    line-height: $font-size-sm;
    margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
  }

  .tile-value {
    font-size: 14px;
    font-weight: 600;
    line-height: $font-size;
    margin-top: $layout-spacing;
  }
}
</style>
