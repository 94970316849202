<template>
  <div class="page-container financial-transaction-page">
    <portal to="page-name">Lançamento financeiro</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Lançamento financeiro</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-12 form-group">
            <label class="form-radio form-inline">
              <input type="radio" name="type" v-model="form.type"
                     value="revenue" :disabled="!!form.id">
              <i class="form-icon"></i> Documento a receber
            </label>
            <label class="form-radio form-inline">
              <input type="radio" name="type" v-model="form.type"
                     value="expense" :disabled="!!form.id">
              <i class="form-icon"></i> Documento a pagar
            </label>
          </div>
          <div class="column col-6 col-sm-12 form-group"
               :class="{'has-error': $v.form.entity.id.$error}">
            <label class="form-label">
              {{ form.type === 'expense' ? 'Favorecido' : 'Pagador' }}
            </label>
            <dx-autocomplete
              id="patient-name"
              v-model="entity"
              :readonly="!!form.entity.id || disabledDocument"
              :source="findEntity"
              label="name"
              track-by="id"
              @select="setEntity"
              :debounce="800"
              placeholder="Nome do paciente, data de nascimento ou CPF"
            >
              <button slot="action" @click="unsetEntity"
                      class="btn btn-action input-group-btn btn-icon"
                      :disabled="disabledDocument"
                      :class="entity ? 'btn-gray' : 'btn-neutral'"
                      tabindex="-1">
                <fa-icon :icon="['fal', entity ? 'times' : 'search']"></fa-icon>
              </button>
              <template v-slot="{ item }">
                <div>{{ item.name }}</div>
                <small class="text-primary" v-if="item.id === null">
                   não encontrado
                </small>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.entity.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.entity.id.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 col-sm-12 form-group"
               :class="{'has-error': $v.form.document.$error}">
            <label for="document" class="form-label">Descrição</label>
            <input type="text" id="document" v-model="form.document" class="form-input"
                   @blur="$v.form.document.$touch()" maxlength="30">
            <template v-if="$v.form.document.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.document.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.issueDate.$error}">
            <label class="form-label">Data de emissão</label>
            <dx-input-date
              id="issue-date"
              class="form-input"
              v-model="form.issueDate"
              @blur="$v.form.issueDate.$touch()"
            />
            <template v-if="$v.form.issueDate.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.issueDate.required">Campo obrigatório</div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.issueDate.date">Data inválida</div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.issueDate.maxDate">
                A emissão não pode ser superior a data atual
              </div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.competenceDate.$error}">
            <label for="competence-date" class="form-label">Competência</label>
            <input type="text" id="competence-date" v-model="form.competenceDate"
                   class="form-input" @blur="$v.form.competenceDate.$touch()"
                   placeholder="00/0000" v-mask="'00/0000'">
            <template v-if="$v.form.competenceDate.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.competenceDate.required">Campo obrigatório</div>
              <div class="form-input-hint"
                   v-if="!$v.form.competenceDate.competenceDate">Data inválida</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.dueDate.$error}" v-if="!isNew">
            <label class="form-label">Data de vencimento</label>
            <dx-input-date
              id="issue-date"
              class="form-input"
              v-model="form.dueDate"
              @blur="$v.form.dueDate.$touch()"
            />
            <template v-if="$v.form.dueDate.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.dueDate.required">Campo obrigatório</div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.dueDate.date">Data inválida</div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.dueDate.minDate">
                Não pode ser inferior a data de emissão
              </div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.value.current.$error}">
            <label class="form-label">Valor total</label>
            <dx-input-number id="current-value"
                             v-model="form.value.current"
                             @blur="$v.form.value.current.$touch()"
                             class="form-input text-right"
                             :disabled="disabledDocument"
                             @change="addInstallments"
                             :precision="2"/>
            <template v-if="$v.form.value.current.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.value.current.required">Campo obrigatório</div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.value.current.minValue">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 col-sm-12 form-group"
               :class="{'has-error': $v.form.categoryId.$error}">
            <label class="form-label">Categoria</label>
            <dx-autocomplete
              id="category"
              v-model="category"
              :readonly="!!form.categoryId || disabledDocument"
              :source="findCategory"
              label="name"
              track-by="id"
              @select="setCategory"
              :debounce="800"
              placeholder="Informe o nome ou pesquise no botão ao lado..."
            >
              <button slot="action" v-if="category" @click="setCategory(null)"
                      class="btn btn-action input-group-btn btn-icon"
                      :disabled="disabledDocument"
                      :class="'btn-gray'"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'times']" />
              </button>
              <button slot="action" v-else
                      @click="openCategoryCostCenterModal('category')"
                      class="btn btn-action input-group-btn btn-icon"
                      :disabled="disabledDocument"
                      :class="'btn-neutral'"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']" />
              </button>
              <template v-slot="{ item }">
                <div v-if="item.id === null">
                  Categoria não encontrada!
                </div>
                <div v-else>
                  <span v-if="item.parent">{{ item.parent.name }} > </span>
                  <strong>{{ item.name }}</strong>
                </div>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.categoryId.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.categoryId.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 col-sm-12 form-group"
               :class="{'has-error': $v.form.costCenterId.$error}">
            <label class="form-label">Centro de custo</label>
            <dx-autocomplete
              id="category"
              v-model="costCenter"
              :readonly="!!form.costCenterId || disabledDocument"
              :source="findCostCenter"
              label="name"
              track-by="id"
              @select="setCostCenter"
              :debounce="800"
              placeholder="Informe o nome ou pesquise no botão ao lado..."
            >
              <button slot="action" v-if="costCenter" @click="unsetCostCenter"
                      class="btn btn-action input-group-btn btn-icon"
                      :disabled="disabledDocument"
                      :class="'btn-gray'"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'times']" />
              </button>
              <button slot="action" v-else
                      @click="openCategoryCostCenterModal('costCenter')"
                      class="btn btn-action input-group-btn btn-icon"
                      :disabled="disabledDocument"
                      :class="'btn-neutral'"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']" />
              </button>
              <template v-slot="{ item }">
                <div v-if="item.id === null">
                  Centro de custo não encontrado!
                </div>
                <div v-else>
                  <span v-if="item.parent">{{ item.parent.name }} > </span>
                  <strong>{{ item.name }}</strong>
                </div>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.costCenterId.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.costCenterId.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 col-sm-12 form-group" v-if="!isNew"
               :class="{'has-error': $v.form.methodId.$error}">
            <label class="form-label">Forma de cobrança</label>
            <select id="method" class="form-select"
                    v-model="form.methodId"
                    @blur="$v.form.methodId.$touch()">
              <option value="">[Selecione uma forma]</option>
              <option v-for="item in methods" :value="item.id"
                      :key="item.id">{{ item.name }}</option>
            </select>
            <template v-if="$v.form.methodId.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.methodId.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 col-sm-12 form-group" v-if="!isNew"
               :class="{'has-error': $v.form.bankAccountId.$error}">
            <label class="form-label">Conta bancária / caixa</label>
            <select id="bank-account" class="form-select"
                    v-model="form.bankAccountId"
                    @blur="$v.form.bankAccountId.$touch()">
              <option value="">[Selecione uma conta]</option>
              <option v-for="item in bankAccounts" :value="item.id" :key="item.id">
                {{ item.name }}
              </option>
            </select>
            <template v-if="$v.form.bankAccountId.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.bankAccountId.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-12 form-group">
            <label class="form-label">Observações</label>
            <textarea id="notes" class="form-input" rows="2"
                      v-model="form.notes"></textarea>
          </div>
        </div>
        <template v-if="isNew">
          <strong>
            Condição de {{ form.type === 'revenue' ? 'recebimento' : 'pagamento'}}
          </strong>
          <div class="divider" />
          <div class="columns">
            <div class="column col-12 form-group">
              <label class="form-radio form-inline">
                <input type="radio" name="payment" v-model="paymentType" value="in_cash">
                <i class="form-icon"></i>À vista ou parcelado
              </label>
              <label class="form-radio form-inline">
                <input type="radio" name="payment" v-model="paymentType" value="repeat">
                <i class="form-icon"></i>Duplicar lançamento
              </label>
            </div>
            <div class="column col-auto form-group" v-if="!isRepeatPayment">
              <label class="form-label">Parcelamento</label>
              <select id="method" class="form-select"
                      v-model="definition.installment">
                <option value="">À vista</option>
                <option v-for="value in installments" :value="value"
                        :key="value">{{ value }}x</option>
              </select>
            </div>
            <div class="column col-2 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.definition.dueDate.$error}">
              <label class="form-label">
                {{ definition.installment > 1 || isRepeatPayment
                ? '1º Vencimento'
                : 'Data de vencimento'
                }}
              </label>
              <dx-input-date
                id="due-date"
                class="form-input"
                v-model="definition.dueDate"
                @change="addInstallments"
                @blur="$v.definition.dueDate.$touch()"
              />
              <template v-if="$v.definition.dueDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.definition.dueDate.date">Data inválida</div>
              </template>
            </div>
            <div class="column col-1 form-group"
                 v-if="definition.installment > 1 || isRepeatPayment">
              <label class="form-label">Intevalo</label>
              <dx-input-number v-model="definition.interval"
                               @change="addInstallments"
                               class="form-input text-center"
              />
            </div>
            <div class="column col-2 col-sm-12 form-group">
              <label class="form-label">Frequência</label>
              <select id="recurrent-repetition" class="form-select"
                      v-model="definition.repetition"
                      @change="addInstallments">
                <option value="days">Dia(s)</option>
                <option value="weeks">Semana(s)</option>
                <option value="months">Mês(es)</option>
                <option value="years">Ano(s)</option>
              </select>
            </div>
            <div class="column col-1 form-group" v-if="isRepeatPayment">
              <label class="form-label">Qtde.</label>
              <dx-input-number v-model="definition.installment"
                               class="form-input text-center"
                               @change="addInstallments"
              />
            </div>
            <div class="column col-sm-12 form-group"
                 :class="{'has-error': $v.definition.methodId.$error}">
              <label class="form-label">Forma de cobrança</label>
              <select id="method" v-model="definition.methodId" class="form-select"
                      @change="addInstallments">
                <option value="">[Selecione uma forma]</option>
                <option v-for="item in methods" :value="item.id"
                        :key="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="column col-sm-12 form-group"
                 :class="{'has-error': $v.definition.bankAccountId.$error}">
              <label class="form-label">Conta bancária / caixa</label>
              <select id="bank-account" class="form-select"
                      v-model="definition.bankAccountId"
                      @change="addInstallments">
                <option value="">[Selecione uma conta]</option>
                <option v-for="item in bankAccounts" :value="item.id" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <table class="table table-hover" v-if="definition.installment > 1 || isRepeatPayment">
            <thead>
            <tr>
              <th class="text-center" style="width:30px">#</th>
              <th style="width:130px">Vencimento</th>
              <th class="text-right" style="width:150px">Valor (R$)</th>
              <th class="text-right" style="width:100px" v-if="!isRepeatPayment">Percentual</th>
              <th>Forma de cobrança</th>
              <th>Conta bancária</th>
            </tr>
            </thead>
            <tbody>
            <table-row
              ref="tableRow"
              v-for="(item, i) in definition.installments" :key="i"
              :idx="i"
              :data="item"
              :bankAccounts="bankAccounts"
              :methods="methods"
              :isRepeatPayment="isRepeatPayment"
            />
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer">
        <button class="btn btn-neutral float-right" v-if="form.id"
                @click="remove">Excluir documento</button>
        <button class="btn btn-primary mr-1" @click="save"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
    <st-loading :show="loading"></st-loading>
    <modal-category-and-cost-center
      v-if="modalCategoryCostCenter.show"
      :show="modalCategoryCostCenter.show"
      :type="modalCategoryCostCenter.type"
      :document-type="form.type"
      @selected="selectedCategoryCostCenter"
      @close="modalCategoryCostCenter.show = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { minValue, required } from 'vuelidate/src/validators';
// import { randomHash } from '@/helpers';
import {
  DELETE_FINANCIAL_TRANSACTION,
  REVERSE_FINANCIAL_TRANSACTION,
} from '@/data/actions/modules/financial';
import formMixin from '../../../../mixins/form';
import {
  date,
  maxDate,
  minDate,
  competenceDate,
} from '../../../../data/validators';
import types from '../../../../data/financial-types';
import TableRow from './Row.vue';
import { mergeFrom } from '../../../../helpers/object';
import ModalCategoryAndCostCenter from './modals/CategoryAndCostCenter.vue';

export default {
  components: {
    ModalCategoryAndCostCenter,
    TableRow,
  },
  mixins: [formMixin],
  data() {
    return {
      isNew: false,
      paymentType: 'in_cash',
      types,
      entity: null,
      category: null,
      costCenter: null,
      bankAccounts: [],
      methods: [],
      installments: [],
      definition: {
        dueDate: moment().format('YYYY-MM-DD'),
        methodId: '',
        bankAccountId: '',
        installment: '',
        interval: 1,
        repetition: 'months',
        installments: [],
      },
      modalCategoryCostCenter: {
        show: false,
        type: 'category',
      },
      form: this.blankForm(),
      loading: false,
      saving: false,
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);

    this.loadMethods();
    this.loadInstallments();
    this.loadBankAccounts();

    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  watch: {
    'form.type': function type() {
      if (this.isNew) {
        this.setCategory(null);
      }
    },
    paymentType: function paymentType() {
      this.clearDefinition();
      this.addInstallments();
    },
    'definition.installment': function type() {
      this.addDueDate();
      this.addInstallments();
    },
  },
  validations() {
    const issueDate = this.form.issueDate || moment().format('YYYY-MM-DD');

    const rules = {
      form: {
        type: { required },
        entity: {
          id: { required },
        },
        categoryId: { required },
        costCenterId: {},
        methodId: {},
        bankAccountId: {},
        issueDate: { required, date, maxDate: maxDate() },
        dueDate: {},
        document: { required },
        competenceDate: { required, competenceDate },
        value: {
          current: { required, minValue: minValue(0.01) },
        },
        status: { required },
      },
      definition: {
        dueDate: { date, minDate: minDate(issueDate) },
        methodId: {},
        bankAccountId: {},
        repetition: {},
      },
    };

    if (this.isNew
      && this.paymentType === 'in_cash'
      && (this.definition.installment === '' || this.definition.installment === 1)) {
      rules.definition = {
        dueDate: { required, date, minDate: minDate(issueDate) },
        methodId: { required },
        bankAccountId: { required },
        repetition: { required },
      };
    }

    if (!this.isNew) {
      rules.form.dueDate = { required, date, minDate: minDate(issueDate) };
      rules.form.methodId = { required };
      rules.form.bankAccountId = { required };
    }

    return rules;
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    disabledDocument() {
      return this.form.services && this.form.services.length > 0;
    },
    canAccessReverseTransaction() {
      if (REVERSE_FINANCIAL_TRANSACTION) {
        return this.$can(REVERSE_FINANCIAL_TRANSACTION);
      }
      return true;
    },
    canAccessRemoveTransaction() {
      if (DELETE_FINANCIAL_TRANSACTION) {
        return this.$can(DELETE_FINANCIAL_TRANSACTION);
      }
      return true;
    },
    isRepeatPayment() {
      return this.paymentType === 'repeat';
    },
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http.get(`/financial-transactions/${this.form.id}`)
        .then(({ data }) => {
          data.competenceDate = moment(data.competenceDate)
            .format('MM/YYYY');
          data.categoryId = data.category.id;
          data.bankAccountId = data.bankAccount.id;
          data.methodId = data.method.id;
          data.costCenterId = data.costCenter ? data.costCenter.id : null;
          this.form = mergeFrom(this.blankForm(), data);
          this.entity = data.entity;
          this.category = data.category;
          this.costCenter = data.costCenter;
          delete data.category;
          delete data.costCenter;
          delete data.method;
          delete data.bankAccount;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadBankAccounts() {
      const params = {
        active: true,
        limit: 0,
      };

      return this.$http.get('/bank-accounts', { params })
        .then(({ data }) => {
          this.bankAccounts = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
      };

      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.methods = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInstallments() {
      this.installments = [];
      for (let i = 1; i <= 60; i += 1) {
        this.installments.push(i);
      }
    },
    findEntity(search) {
      const params = {
        search: `^${search}`,
        type: this.form.type,
      };

      return this.$http.get('/financial-transactions/entities', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setEntity(data) {
      this.form.entity = data || this.blankForm().entity;
    },
    unsetEntity() {
      this.setEntity(null);
      this.entity = null;
    },
    findCategory(search) {
      const params = {
        active: true,
        allowsValue: true,
        type: this.form.type,
        limit: 0,
        search,
      };

      return this.$http.get('/categories', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setCategory(data) {
      this.category = null;
      this.form.categoryId = '';
      if (data && data.id) {
        this.category = {
          id: data.id,
          name: data.name,
        };
        this.form.categoryId = data.id;
      }
    },
    findCostCenter(search) {
      const params = {
        active: true,
        allowsValue: true,
        limit: 0,
        search,
      };

      return this.$http.get('/cost-centers', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setCostCenter(data) {
      this.costCenter = null;
      this.form.costCenterId = '';
      if (data && data.id) {
        this.costCenter = {
          id: data.id,
          name: data.name,
        };
        this.form.costCenterId = data.id;
      }
    },
    unsetCostCenter() {
      this.costCenter = null;
      this.setCostCenter(null);
    },
    openCategoryCostCenterModal(type) {
      this.modalCategoryCostCenter.type = type;
      this.modalCategoryCostCenter.show = true;
    },
    selectedCategoryCostCenter(item) {
      if (this.modalCategoryCostCenter.type === 'category') {
        this.form.categoryId = item.id;
        this.category = {
          id: item.id,
          name: item.name,
        };
      } else {
        this.form.costCenterId = item.id;
        this.costCenter = {
          id: item.id,
          name: item.name,
        };
      }
      this.modalCategoryCostCenter.show = false;
    },
    clearDefinition() {
      this.definition.methodId = '';
      this.definition.bankAccountId = '';
      this.definition.interval = 1;
      this.definition.repetition = 'months';
      this.definition.installments = [];
      if (this.paymentType === 'repeat') {
        this.definition.installment = 1;
      } else {
        this.definition.installment = '';
      }
    },
    addInstallments() {
      if (!this.isNew) {
        return;
      }

      if (!this.definition.dueDate) {
        this.addDueDate();
      }
      if (!this.definition.interval) {
        this.definition.interval = 1;
      }
      if (this.paymentType === 'repeat' && !this.definition.installment) {
        this.definition.installment = 1;
      }

      let { dueDate } = this.definition;
      let value = this.form.value.current;
      let percent = 100;

      if (!this.isRepeatPayment) {
        value = this.form.value.current / this.definition.installment;
        percent /= this.definition.installment;
      }

      let totalValue = 0;
      let totalPercent = 0;
      this.definition.installments = [];
      for (let i = 0; i < this.definition.installment; i += 1) {
        this.definition.installments.push({
          id: null,
          issueDate: this.form.issueDate || moment().format('YYYY-MM-DD'),
          dueDate,
          value,
          percent,
          methodId: this.definition.methodId,
          bankAccountId: this.definition.bankAccountId,
        });
        totalValue += Math.round(value * 100) / 100;
        totalPercent += Math.round(percent * 100) / 100;
        dueDate = moment(dueDate)
          .add(this.definition.interval, this.definition.repetition)
          .format('YYYY-MM-DD');
      }

      if (this.definition.installments.length > 0 && !this.isRepeatPayment) {
        this.definition.installments[0].value += this.form.value.current - totalValue;
        this.definition.installments[0].percent += 100 - totalPercent;
      }
    },
    addDueDate() {
      if (this.definition.installment === '') {
        this.definition.dueDate = moment().format('YYYY-MM-DD');
      } else {
        this.definition.dueDate = moment().add(1, 'months').format('YYYY-MM-DD');
      }
    },
    save() {
      this.$v.form.$touch();
      this.$v.definition.$touch();
      if (this.$v.form.$error || this.$v.definition.$error) {
        return;
      }

      if (this.isNew) {
        let tableRowValid = true;
        if ((this.definition.installment === 1 && this.paymentType === 'repeat')
          || this.definition.installment > 1) {
          for (let i = 0; i < this.definition.installments.length; i += 1) {
            tableRowValid = this.$refs.tableRow[i].validate();
          }
        }
        if (!tableRowValid) {
          return;
        }
      }

      this.saving = true;

      const data = this.clone(this.form);
      const [month, year] = data.competenceDate.split('/');
      data.competenceDate = `${year}-${month}-01`;

      data.installments = [];
      if (this.isNew) {
        data.value.total = data.value.current;
        if ((this.definition.installment === 1 && this.paymentType === 'repeat')
          || this.definition.installment > 1) {
          data.installments = this.definition.installments;
        } else {
          data.installments.push({
            id: null,
            dueDate: this.definition.dueDate,
            value: this.form.value.current,
            percent: 100,
            methodId: this.definition.methodId,
            bankAccountId: this.definition.bankAccountId,
          });
        }
      }

      const request = this.isNew
        ? this.$http.post('/financial-transactions', data)
        : this.$http.put(`/financial-transactions/${this.form.id}`, data);

      request
        .then(() => {
          this.$toast.show('Lançamento salvo com sucesso!');
          this.$router.back();
        })
        .catch(() => {})
        .finally(() => {
          this.saving = false;
        });
    },
    remove() {
      if (!this.canAccessRemoveTransaction) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      if (!this.form.id) {
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este documento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: close => this.$http
              .delete(`/financial-transactions/${this.form.id}`)
              .catch(() => {})
              .then(() => {
                close();
                this.$router.back();
              }),
          },
        ],
      });
    },
    blankForm() {
      return {
        id: '',
        type: 'revenue',
        status: 'open',
        categoryId: '',
        costCenterId: '',
        methodId: '',
        bankAccountId: '',
        issueDate: moment().format('YYYY-MM-DD'),
        dueDate: '',
        competenceDate: moment().format('MM/YYYY'),
        document: '',
        bundleId: '',
        entity: {
          id: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
          type: '',
        },
        installment: {
          total: 1,
          current: 1,
        },
        value: {
          rate: '',
          total: '',
          current: '',
        },
        payments: [],
        services: [],
        active: true,
        reconciliation: false,
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  @import 'src/assets/scss/mixins';
  .financial-transaction-page {
  }
</style>
