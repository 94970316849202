<template>
  <div class="page-container financial-payment-page">
    <portal to="page-name">
      {{ form.type === 'expense' ? 'Pagamento' : 'Recebimento' }} de conta
    </portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">
          {{ form.type === 'expense' ? 'Pagamento' : 'Recebimento' }} de conta
        </h1>
      </div>
      <div class="card-body center">
        <div class="transaction-tiles">
          <div class="tile-item">
            <div class="tile-label">Documento</div>
            <div class="tile-value">{{ form.document }}</div>
          </div>
          <div class="tile-item">
            <div class="tile-label">Data de Emissão</div>
            <div class="tile-value">{{ form.issueDate }}</div>
          </div>
          <div class="tile-item">
            <div class="tile-label">Data de Vencimento</div>
            <div class="tile-value">{{ form.dueDate }}</div>
          </div>
        </div>
        <div class="divider"></div>
        <form class="form-horizontal mt-2">
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="entity">
                {{ form.type === 'expense' ? 'Favorecido' : 'Pagador' }}
              </label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="entity" v-model="form.entity.name"
                     class="form-input text-bold" readonly>
            </div>
          </div>
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="method">Forma de cobrança</label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="method" v-model="form.method.name"
                     class="form-input" readonly>
            </div>
          </div>
          <div class="form-group" :class="{'has-error': $v.form.category.id.$error}">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="category">Categoria</label>
            </div>
            <div class="col-4 col-sm-12">
              <select id="category" v-model="form.category.id" class="form-select"
                      @change="$v.form.category.id.$touch()">
                <option value="">[Selecione uma categoria]</option>
                <option v-for="item in categories" :value="item.id" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
              <template v-if="$v.form.category.id.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.category.id.required">Campo obrigatório</div>
              </template>
            </div>
          </div>
          <div class="form-group" :class="{'has-error': $v.form.costCenter.id.$error}">
            <div class="col-3 col-sm-12">
              <label class="form-label">Centro de custo</label>
            </div>
            <div class="col-4 col-sm-12">
              <select id="cost-center" v-model="form.costCenter.id" class="form-select"
                      @change="$v.form.costCenter.id.$touch()">
                <option value="">[Selecione um centro de custo]</option>
                <option v-for="item in costCenters" :value="item.id" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
              <template v-if="$v.form.costCenter.id.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.costCenter.id.required">Campo obrigatório</div>
              </template>
            </div>
          </div>
          <div class="form-group" :class="{'has-error': $v.form.bankAccount.id.$error}">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="bank-account">Conta bancária / caixa</label>
            </div>
            <div class="col-4 col-sm-12">
              <select id="bank-account" v-model="form.bankAccount.id" class="form-select"
                      @change="$v.form.bankAccount.id.$touch()">
                <option value="">[Selecione uma conta]</option>
                <option v-for="item in bankAccounts" :value="item.id" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
              <template v-if="$v.form.bankAccount.id.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.bankAccount.id.required">Campo obrigatório</div>
              </template>
            </div>
          </div>
          <div class="form-group" :class="{'has-error': $v.form.payment.method.id.$error}">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="payment-method">
                Forma de {{ form.type === 'expense' ? 'pagamento' : 'recebimento' }}
              </label>
            </div>
            <div class="col-4 col-sm-12"
                 :class="{'has-error': $v.form.payment.method.id.$error}">
              <select id="payment-method" v-model="form.payment.method.id" class="form-select"
                      @change="$v.form.payment.method.id.$touch()">
                <option value="">[Selecione uma forma de cobrança]</option>
                <option v-for="item in methods" :value="item.id"
                        :key="item.id">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.payment.method.id.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.payment.method.id.required">Campo obrigatório</div>
              </template>
            </div>
          </div>
          <div class="form-group" :class="{'has-error': $v.form.payment.date.$error}">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="payment-date">
                Data do {{ form.type === 'expense' ? 'pagamento' : 'recebimento' }}
              </label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="payment-date" v-model="form.payment.date"
                     class="form-input text-right" @change="$v.form.payment.date.$touch()"
                     placeholder="00/00/0000" v-mask-date.br>
              <template v-if="$v.form.payment.date.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.payment.date.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.payment.date.date">Data inválida</div>
                <div class="form-input-hint"
                   v-else-if="!$v.form.payment.date.maxDate">Não pode ser superior a hoje</div>
              </template>
            </div>
          </div>
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="current-value">Valor</label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="current-value" v-model="form.value.current"
                     class="form-input text-right" placeholder="0,00"
                     v-mask-decimal.br="2" readonly>
            </div>
          </div>
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="fine-value">Multa</label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="fine-value" v-model="form.payment.fine"
                     class="form-input text-right" placeholder="0,00" v-mask-decimal.br="2">
            </div>
          </div>
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="interest-value">Acréscimo</label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="interest-value" v-model="form.payment.interest"
                     class="form-input text-right" placeholder="0,00" v-mask-decimal.br="2">
            </div>
          </div>
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="discount-value">Desconto</label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="discount-value" v-model="form.payment.discount"
                     class="form-input text-right" placeholder="0,00" v-mask-decimal.br="2">
            </div>
          </div>
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label" for="discount-value">Taxa</label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="rate-value" v-model="form.payment.rate"
                     class="form-input text-right" placeholder="0,00" v-mask-decimal.br="2">
            </div>
          </div>
          <div class="form-group">
            <div class="col-3 col-sm-12">
              <label class="form-label text-bold" for="total">Saldo</label>
            </div>
            <div class="col-4 col-sm-12">
              <input type="text" id="total" :value="sumTotal" class="form-input text-right"
                     placeholder="0,00" v-mask-decimal.br="2" readonly>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save"
                :disabled="saving" :class="{loading: saving}">
          Realizar {{ form.type === 'expense' ? 'pagamento' : 'recebimento' }}
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>

    <st-loading :show="loading"></st-loading>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/src/validators';
import {
  date,
  maxDate,
} from '../../../../data/validators';
import types from '../../../../data/financial-types';
import formMixin from '../../../../mixins/form';
import { mergeFrom } from '../../../../helpers/object';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/financial-transactions',
      types,
      entities: [],
      bankAccounts: [],
      categories: [],
      costCenters: [],
      methods: [],
      form: this.blankForm(),
      loading: false,
      saving: false,
    };
  },
  mounted() {
    this.loadCategories();
    this.loadCostCenters();
    this.loadBankAccounts();
    this.loadMethods();
    this.form.id = this.$route.params.id;
    this.load();
  },
  validations() {
    const form = {
      bankAccount: {
        id: { required },
      },
      category: {
        id: { required },
      },
      costCenter: {
        id: {},
      },
      status: { required },
      payment: {
        method: {
          id: { required },
        },
        date: { required, date, maxDate: maxDate() },
      },
    };

    return { form };
  },
  computed: {
    sumTotal() {
      let total = (this.decimalToApi(this.form.value.current)
         + this.decimalToApi(this.form.payment.fine)
         + this.decimalToApi(this.form.payment.interest))
         - this.decimalToApi(this.form.payment.discount)
         - this.decimalToApi(this.form.payment.rate);

      total = total < 0 ? 0 : total;
      // eslint-disable-next-line
      this.form.payment.total = total;
      return total.toFixed(2);
    },
  },
  methods: {
    load() {
      this.loading = true;
      this.apiGetAccount(this.form.id)
        .then((data) => {
          this.form = mergeFrom(this.blankForm(), data);
          this.form.issueDate = this.dateFromApi(data.issueDate, true);
          this.form.dueDate = this.dateFromApi(data.dueDate, true);
          this.form.value.current = data.value.current.toFixed(2);
          this.form.payment = {
            method: {
              id: this.form.method.id,
            },
            date: moment().format('DD/MM/YYYY'),
            rate: data.value.rate ? data.value.rate.toFixed(2) : 0,
          };
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      if (!this.form.payment.total || this.form.payment.total === 0) {
        this.$toast.show('O saldo da baixa não pode ser zero', { type: 'error' });
        return;
      }

      // this.saving = true;

      const data = {
        id: this.form.id,
        status: 'paid',
        categoryId: this.form.category.id,
        costCenterId: this.form.costCenter.id,
        bankAccountId: this.form.bankAccount.id,
        payment: {
          date: this.dateToApi(this.form.payment.date),
          methodId: this.form.payment.method.id,
          fine: this.decimalToApi(this.form.payment.fine),
          interest: this.decimalToApi(this.form.payment.interest),
          rate: this.decimalToApi(this.form.payment.rate),
          discount: this.decimalToApi(this.form.payment.discount),
          total: this.decimalToApi(this.form.payment.total),
        },
      };

      this.$http.post(`${this.path}/${this.form.id}/payment`, data)
        .then(() => {
          this.$toast.show('Lançamento realizado com sucesso');
          this.$router.back();
        })
        .catch((e) => {
          let errorMessage = 'Ocorreu um erro ao realizar a impressão!';
          if (e.response) {
            if (e.response.status === 403) {
              errorMessage = 'Acesso não autorizado';
            } else if (e.response.data && e.response.data.message) {
              errorMessage = e.response.data.message;
            }
          }
          this.$toast.show(errorMessage, { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    loadBankAccounts() {
      const params = {
        active: true,
        limit: 0,
      };

      this.apiGetBankAccounts(params)
        .then((data) => {
          this.bankAccounts = data.items;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
      };

      this.apiGetMethods(params)
        .then((data) => {
          this.methods = data.items;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadCategories() {
      const params = {
        active: true,
        limit: 0,
      };

      this.apiGetCategories(params)
        .then((data) => {
          this.categories = data.items;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadCostCenters() {
      const params = {
        active: true,
        limit: 0,
      };

      return this.$http.get('/cost-centers', { params })
        .then(({ data }) => {
          this.costCenters = data.items;
        })
        .catch(() => {});
    },
    apiGetAccount(id) {
      return this.$http.get(`${this.path}/${id}`)
        .then(({ data }) => data)
        .catch(() => {});
    },
    apiGetMethods(params) {
      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => data)
        .catch(() => {});
    },
    apiGetBankAccounts(params) {
      return this.$http.get('/bank-accounts', { params })
        .then(({ data }) => data)
        .catch(() => {});
    },
    apiGetCategories(params) {
      return this.$http.get('/categories', { params })
        .then(({ data }) => data)
        .catch(() => {});
    },
    blankForm() {
      return {
        id: '',
        entity: {
          id: '',
          name: '',
        },
        type: 'revenue',
        status: 'open',
        method: {
          id: '',
          name: '',
        },
        category: {
          id: '',
          name: '',
        },
        costCenter: {
          id: '',
          name: '',
        },
        bankAccount: {
          id: '',
          name: '',
        },
        issueDate: moment().format('DD/MM/YYYY'),
        competenceDate: moment().format('MM/YYYY'),
        dueDate: '',
        document: '',
        bundleId: '',
        installment: {
          total: '',
          current: '',
        },
        value: {
          total: '',
          rate: '',
          current: '',
          final: '',
        },
        notes: '',
        payment: {
          method: {
            id: '',
            name: '',
          },
          date: '',
          total: '',
          fine: '',
          interest: '',
          discount: '',
          rate: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  @import 'src/assets/scss/mixins';
  .financial-payment-page {
    .transaction-tiles {
      display: flex;
      padding: 0 0 $layout-spacing 0;
      color: #fff;
      .tile-item {
        background-color: $secondary-color;
        border-radius: $border-radius;
        flex: 1;
        margin-left: $layout-spacing;
        padding: 10px;
        position: relative;
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
      }
      .tile-label {
        font-size: $font-size-sm;
        line-height: $font-size-sm;
        margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
      }
      .tile-value {
        font-size: 14px;
        font-weight: 600;
        line-height: $font-size;
        margin-top: $layout-spacing;
      }
    }
  }
</style>
